<template>
  <div class="stage">
    <div class="header">
      <section class="hero is-medium">
        <div class="hero-body">
          <div class="">
            <h3 class="title">Stage</h3>
          </div>
        </div>
      </section>
      <div class="return" @click="displayStage(0)" v-if="choosenValue != 0">
        <img src="@/assets/icons/return.png" alt="return" />
      </div>
    </div>
    <div class="stage-body">
      <h1 class="content" style="margin-top: 35px">
        STAGE DE LA ST CYR<br />
        •⁠ ⁠Accueil entre 9h et 9h30<br />
        •⁠ ⁠⁠Repas du midi à prévoir <br />
        •⁠ ⁠⁠Goûter offert <br />
        •⁠ ⁠⁠Retour en famille entre 17h et 17h30 <br />
      </h1>
      <div class="convocations-menu" v-if="choosenValue == 0">
        <div
          class="columns is-multiline is-mobile"
          style="justify-content: center"
        >
          <div
            class="column is-half-mobile is-one-quarter-desktop"
            style="width: 100%"
          >
            <div class="category-item title is-4" @click="displayStage(1)">
              Stage de football <br />(10,11,13 & 14 Février)
            </div>
          </div>
        </div>
      </div>

      <div class="part" v-if="choosenValue == 1">
        <img src="./../assets/stage/stage_foot.jpeg" alt="" />
        <iframe
          class="responsive-iframe"
          id="haWidget"
          allowtransparency="true"
          scrolling="auto"
          src="https://www.helloasso.com/associations/saint-cyr-herbignac-football/evenements/stage-de-fevrier/widget"
          style="
            width: 94%;
            height: 1150px;
            margin-top: 10px;
            border: none;
            margin-left: 3%;
          "
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: function () {
    return {
      choosenValue: 0,
    };
  },
  methods: {
    displayStage(value) {
      this.choosenValue = value;
    },
    downloadBonCommande() {
      axios
        .post(
          "public/stage/download",
          {
            file_name: "Bon de commande SCH 2022_2023.xlsx",
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Bon de commande SCH 2022_2023.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },
  },
};
</script>

<style lang="scss">
.stage {
  height: 100%;

  .hero {
    text-align: center;
    h3 {
      margin-left: 0;
    }

    &::before {
      background-image: url("@/assets/hero/stage.jpg");
    }
    .title {
      color: #000000;
    }
  }

  .return {
    height: 50px;
    width: 70px;
    background-color: #e75913;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
    z-index: 2;
    position: relative;
    margin: -70px auto 0 5%;
    padding: 20px;
    display: flex;
    align-items: center;

    img {
      vertical-align: middle;
    }
  }

  .picture {
    margin-bottom: 20px;
    margin-top: 30px;
    text-align: -webkit-center;

    .image {
      max-width: 90%;
      max-height: 60%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 20px;
      }
    }
  }

  .stage-body {
    text-align: -webkit-center;

    .download {
      color: #000000;
      background-color: #ec8a5a;
      border-radius: 10px;
      border: 0;
      cursor: pointer;
      font-weight: bold;
      padding: 5px;
    }

    .responsive-iframe {
      width: 100%;
      height: 100%; /* Prendre toute la hauteur du conteneur */
      border: none; /* Retirer les bordures */
      overflow: hidden; /* Empêcher le scroll */
    }

    .content {
      width: 80%;
      font-size: large;
    }

    .convocations-menu {
      margin: 50px 20px 50px 20px;
      height: 100%;
      .category-item {
        padding: 1.5rem;
        text-align: center;
        background-color: #e75913;
        color: #000000;
        border-radius: 15px;
      }
      .category-item:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
